<template>
    <v-row class="fill-height">
        <v-col cols="12" class="mt-1 pl-6 pr-6" style="max-width: 760px">
            <!-- <div class="d-flex justify-center mb-12 mt-6">
                <img src="/svg/otc_logo.svg"
                    width="120px"
                />
            </div> -->

            <ValidationObserver ref="observer"
                                v-slot="{ invalid }"
                                class="mt-8"
            >

                <form class="form"
                    style="width: 100%"
                    action=""
                    @submit.prevent="sendMoney"
                >
                <div>
                        <label class="login_label grey--text">
                            보내는 메세지
                        </label>
                        <ValidationProvider v-slot="{ errors }"
                                            name="이름"
                                            rules="required"
                                            mode="aggressive"
                        >
                            <v-text-field v-model="add_pay.msg"
                                        class="mt-1"
                                        outlined
                                        :error-messages="errors"
                                        dense
                                        required
                                        v-on:keyup.enter="sendMoney"
                            ></v-text-field>
                        </ValidationProvider>
                    </div>

                    <!-- <div>
                        <label class="login_label grey--text">
                            Email
                        </label>
                        <ValidationProvider v-slot="{ errors }"
                                            name="Email"
                                            rules="required|email"
                                            mode="aggressive"
                        >
                            <v-text-field v-model.trim="add_pay.user_email"
                                        class="mt-1"
                                        outlined
                                        :error-messages="errors"
                                        dense
                                        required
                                        v-on:keyup.enter="sendMoney"
                            ></v-text-field>
                        </ValidationProvider>
                    </div> -->

                    

                    <div>
                        <label class="login_label grey--text">
                            받을사람 휴대폰번호
                        </label>
                        <ValidationProvider v-slot="{ errors }"
                                            name="phone"
                                            mode="aggressive"
                                            rules="required|min:11"
                        >
                            <v-text-field v-model="add_pay.phone"
                                        class="mt-1"
                                        outlined
                                        type="phone"
                                        :error-messages="errors"
                                        dense
                                        required
                                        v-on:keyup.enter="sendMoney"
                            ></v-text-field>
                        </ValidationProvider>
                    </div>

                    <v-radio-group v-model="radioGroup">
                        <v-radio
                            v-for="(row, i) in radio"
                            @change="changeRadio(row)"
                            :key="i"
                            :label="row.name"
                        >
                        </v-radio>

                        <v-text-field
                            v-if="radioGroup == 3"
                            v-model="etc_money"
                            style="width: 150px"
                            type="number"
                            dense
                            outlined
                            hide-details
                        ></v-text-field>
                    </v-radio-group>

                    <div class="d-flex">
                        <v-col cols="6">
                            <v-btn block color="grey" dark @click="backPage">
                                취소
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn block color="primary" depressed  
                                @click="sendMoney" :disabled="invalid" style="color:white">
                                보내기
                            </v-btn>
                        </v-col>
                    </div>
                </form>
            </ValidationObserver>
        </v-col>


        <!-- *************************************************************************** -->
        <!-- SnackBar : Start  ********************************************************* -->
        <v-snackbar v-model="snackbar">
            {{ snackbar_msg }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
        <!-- SnackBar : End  ************************************************************** -->
        <!-- ****************************************************************************** -->
        <v-overlay :value="loading_bar">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </v-row>
</template>

<script>
import axios from "../../../service/axios";
import { required, email, min } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
setInteractionMode('eager');
extend('email', {
    ...email,
    message: 'Email 형식을 확인해주세요',
});
extend('required', {
    ...required,
    message: '{_field_} 입력해주세요',
});
// extend('phone', {
//     params: ['target'],
//     validate(value, { target }) {
//         return value === target;
//     },
//     message: '-제거'
// });
extend('min', {
    ...min,
    message: '- 를 제거 하고 입력해주세요',
});
export default {
    name: "SendMoney",
    components: {
        ValidationObserver,
        ValidationProvider,
    },
    data() {
        return {
            radioGroup: 0,
            radio: [
                {
                    name: "10,000원",
                    value: "10,000원",
                },
                {
                    name: "20,000원",
                    value: "20,000원",
                },
                {
                    name: "30,000원",
                    value: "30,000원",
                },
                {
                    name: "기타",
                    // value: null,
                },
            ],
            // radio: [
            //     "10,000원", "20,000원", "30,000원", "기타"
            // ],
            etc_money: null,
            add_pay: {
                // user_email: null,
                msg: '송금',
                phone: null,
                value: "10,000원"
            },
            snackbar: false,
            snackbar_msg: null,
            loading_bar: false, 
        }; // return
    },
    created() {
        this.add_pay = this.radio[0];
        console.log('this.radio[0] : ', this.radio[0])
        console.log('this.add_pay : ', this.add_pay)
        // this.$store.commit(
        //     {
        //         type: "setSubPage",
        //         sub_page: '충전금액',
        //     }
        // );
        // let test = this.$store.getters.getSubPage
        // console.log('Sub Page Name : ', test)
    },
    methods: {
        changeRadio(item) {
            console.log('item : ', item)
            if (this.radioGroup != 3) {
                this.etc_money = null;
            }
            this.add_pay.value = item.value;
        },
        backPage() {
            this.$router.go(-1);
        },
        sendMoney() {
            if (this.radioGroup == 3) {
                this.add_pay.value = this.etc_money;
            }
            let str = this.add_pay.value
            let cleanData = Number(str.replace(/,|원/g, ''))
            this.add_pay.value = cleanData
            this.add_pay.phone = '01054600300'

            const myMoney = this.$store.getters.getBalance;

            if(myMoney >= cleanData && cleanData>=10){
                // console.log('OK : ', this.add_pay.value)
                this.askSend();
            } else {
                // console.log('Not enough Money : ',this.add_pay.value)
                this.snackbar = true;
                this.snackbar_msg = "잔액이 부족합니다.";
            }
        },

        askSend() {
            // this.buy_dialog = false;
            // var product_name = `사이콘캐쉬 충전 - ${sale_price}`;
            this.loading_bar = true
            const params = {
                accessKey: this.$cookies.get("token"),
                options: this.add_pay,
                type: "송금",
            };
            axios
                .post("/payment/sendMoney", params)
                .then((res) => {
                    this.loading_bar = false;
                    let serverCash = res.data.data.item
                    if(serverCash == 0) {
                        this.snackbar = true;
                        this.snackbar_msg = "송금이 완료됐습니다.";
                    } else {
                        this.$store.commit({ type: "setBalance", balance: serverCash })
                        this.$router.replace('/home/home')
                        // console.log('Res : ', res.data.data.item)
                        this.snackbar = true;
                        this.snackbar_msg = "송금이 완료됐습니다.";
                    }
                    // this.$router.go(-1)
                    this.$router.replace("/");

                })
                .catch((error) => {
                    console.log("페스트 오류", error);
                });
        },
    },
};
</script>

<style scoped>
</style>